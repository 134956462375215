import React from 'react';
import { useHistory } from 'react-router';
import { Col, Table } from 'antd';

const WorkOrdersTable = ({loading = false, workOrders = []}) => {
    const history = useHistory();

    const dataColumns = [
        {title: 'Apertura', dataIndex: 'openTimestamp', defaultSorter: 'ascend', sorter: (a,b) => a.openTimestamp > b.openTimestamp},
        {dataIndex: 'name'},
        {title: 'Estado', dataIndex: 'status', defaultSorter: 'ascend', sorter: (a,b) => a.status > b.status}
    ];

    return (
        <Col offset={1} span={22}>
            <Table
                style={{cursor: 'pointer'}}
                size="middle"
                columns={dataColumns}
                rowKey={'id'}
                dataSource={workOrders}
                scroll={{y: 440}}
                locale={{
                    emptyText: 'No se encontraron datos',
                    cancelSort: 'Haz clic para cancelar ordenar',
                    triggerAsc: 'Haz clic para ordenar - ascendente',
                    triggerDesc: 'Haz clic para ordenar - descendente'
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            history.push('/app/maintenance/work-orders/' + record.id);
                        }
                    };
                }}
                pagination={{
                    pageSize: 8,
                    showTotal: (total) => `Total: ${total}`
                }}
                loading={{
                    spinning: loading,
                    delay: 500,
                    size: 'large'
                }}
            />
        </Col>
    );
};

export default WorkOrdersTable;