import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Space, Tabs } from 'antd';

import { errorNotification } from '../../../components/UI/Notifications/Notifications';

import MaintenanceService from '../../../services/maintenance';
import { disableHotelSelector, enableHotelSelector } from '../../../store/actions';
import SectionTitle from '../../../components/UI/Panels/SectionTitle/SectionTitle';
import CreateButton from '../../../components/UI/Buttons/CreateButton/CreateButton';
import ReloadButton from '../../../components/UI/Buttons/ReloadButton/ReloadButton';
import WorkOrdersTable from './WorkOrdersTable/WorkOrdersTable';


const WorkOrders = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const hid = useSelector(state => state.ui.selectedHotel ?? 0);

    const [loading, setLoading] = useState(true);
    const [openOrders, setOpenOrders] = useState([]);
    const [validateOrders, setValidateOrders] = useState([]);
    const [closeOrders, setCloseOrders] = useState([]);


    useEffect(() => {
        dispatch(enableHotelSelector());

        return () => {
            dispatch(disableHotelSelector());
        };
    }, []);

    useEffect(() => {
        if (hid != null && hid > 0) {
            loadData();
        }
    }, [hid]);

    const loadData = async () => {
        setLoading(true);

        await loadWorkOrders();

        setLoading(false);
    };

    const loadWorkOrders = async () => {
        await MaintenanceService.getWorkOrders(hid)
            .then(res => {
                if (res != null) {
                    let openedRes = [];
                    let validatedRes = [];
                    let closedRes = [];

                    res.forEach(order => {
                        let a = order?.openTimestamp ?? [-999999, 1, 1];
                        let openTimestamp = new Date(a[0], (a[1] - 1), a[2]).toLocaleDateString()
                        let state = calculateStatus(order.closeUserId, order.validationUserId);
                        let processedOrder = {
                            id: order.id,
                            openTimestamp: openTimestamp,
                            name: order.name ?? '',
                            status: state
                        };

                        if (processedOrder.status === 'Abierta') {
                            openedRes.push(processedOrder);
                        } else if (processedOrder.status === 'Cerrada') {
                            closedRes.push(processedOrder);
                        } else {
                            validatedRes.push(processedOrder);
                        }
                    });

                    setOpenOrders(openedRes);
                    setValidateOrders(validatedRes);
                    setCloseOrders(closedRes);
                }
            })
            .catch(err => errorNotification(err));
    };

    const calculateStatus = (closeUserId, validationUserId) => {
        if (validationUserId != null && validationUserId > 0) {
            return 'Cerrada';
        }

        if (closeUserId != null && closeUserId > 0) {
            return 'Lista para validar';
        }

        return 'Abierta';
    };

    const goToAdd = () => {
        history.push('/app/maintenance/work-orders-add');
    }

    return (
        <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
            <Col offset={22} span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Space>
                    <CreateButton onClick={goToAdd} loading={loading} />
                    <ReloadButton onClick={loadData} loading={loading}/>
                </Space>
            </Col>
            <SectionTitle title='Órdenes de trabajo'/>

            <Tabs defaultActiveKey={1} style={{width: '100%', height: '100%'}}>
                <Tabs.TabPane key={1} tab='Abiertas'><WorkOrdersTable loading={loading} workOrders={openOrders}/></Tabs.TabPane>
                <Tabs.TabPane key={2} tab='Listas para validar'><WorkOrdersTable loading={loading} workOrders={validateOrders}/></Tabs.TabPane>
                <Tabs.TabPane key={3} tab='Cerradas'><WorkOrdersTable loading={loading} workOrders={closeOrders}/></Tabs.TabPane>
            </Tabs>
        </Row>
    );
}

export default WorkOrders;