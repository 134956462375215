import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { Breadcrumb, Button, Col, InputNumber, Row, Select, Space, Spin, Steps, Table } from 'antd';

import SectionTitle from '../../../../components/UI/Panels/SectionTitle/SectionTitle';

import MaintenanceService from '../../../../services/maintenance';
import { errorNotification } from '../../../../components/UI/Notifications/Notifications';


const AddSupplies = () => {
    const {workOrderId} = useParams();

    const hid = useSelector(state => state.ui.selectedHotel ?? 0);
    const username = useSelector(state => state.auth.user?.username ?? '');

    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(null);
    const [renderedStep, setRenderedStep] = useState(null);

    const [inventories, setInventories] = useState([]);
    const [selectedInventory, setSelectedInventory] = useState(null);

    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [tableItems, setTableItems] = useState([]);

    const editColumns = [
        {title: 'Artículo', dataIndex: 'name'},
        {title: 'Cantidad en inventario', dataIndex: 'quantity'},
        {title: 'Cantidad a utilizar', render: (_,e) => <InputNumber min={0} step={1} max={e?.quantity ?? 1000000} onChange={val => updateItemQuantity(e.id, val)}/>}
    ];


    useEffect(() => {
        loadInventories();
    }, []);

    useEffect(() => {
        if (selectedInventory != null) {
            loadInventoryItems();
        }
    }, [selectedInventory])

    useEffect(async () => {
        switch (step) {
            case 0:
                setRenderedStep(selectInventory);
                break;
            case 1:
                setRenderedStep(selectInventoryItems)
                break;
            case 2:
                setRenderedStep(setSupplies);
                break;
            case 3:
                await addSupplies();
                setRenderedStep(completeTransaction);
                break;
            default:
                break;
        }
    }, [step]);

    useEffect(() => {
        setTableItems([]);
        const processedItems = [];
        selectedItems.forEach(i => {
            const article = items.find(a => {return a.id == i;});
            article['newQuantity'] = 0;
            processedItems.push(article);
        });
        setTableItems(processedItems);
    }, [selectedItems]);

    const updateItemQuantity = (itemId, value) => {
        let processedItems = tableItems;
        processedItems.forEach(pi => {
            if (pi.id === itemId) {
                pi.newQuantity = value;
            }
        });
        
        setTableItems(processedItems);
    };

    const loadInventories = async () => {
        setLoading(true);

        await API.get('benackoAPI', '/inventories')
            .then(res => {
                if (res != null) {
                    let processedOptions = [];
                    const lHid = parseInt(hid);
                    res.forEach(inv => {
                        if (inv.hotelId != null && inv.hotelId === lHid && inv.maintenance != null && inv.maintenance === 1) {
                            processedOptions.push({
                                value: inv.id,
                                label: inv.name
                            });
                        }
                    });

                    setInventories(processedOptions);
                }
            })
            .then(() => setStep(0))
            .catch(err => errorNotification(err))
            .finally(() => setLoading(false));
    };

    const loadInventoryItems = async () => {
        setLoading(true);

        await API.get('benackoAPI', '/v2/inventory-items?inventoryId=' + selectedInventory)
            .then(res => {
                let processedItems = [];
                res.forEach(item => processedItems.push({
                    value: item.id,
                    label: item.name
                }));

                setOptions(processedItems);
                setItems(res);
            })
            .catch(err => errorNotification(err))
            .finally(() => setLoading(false));
    };

    const addSupplies = async () => {
        if (tableItems != null) {
            tableItems.forEach(async item => {
                await MaintenanceService.addWorkOrderSupply(workOrderId, item.id, item.name, item.unit, item.newQuantity)
                    .then(async () => await editItem(item))
                    .catch(err => errorNotification(err));
            });
        }
    };

    const editItem = async (item) => {
        const newQty = item.quantity - item.newQuantity;

        await API.put('benackoAPI','/inventory-items/' + item.id, {
                    body: {
                        quantity: newQty,
                        supplyFlag: false
                    }
                })
                .then(() => {
                    const inventoryAction = {
                        inventoryId: parseInt(selectedInventory, 10),
                        action: 'sacar artículo',
                        itemId: parseInt(item.id, 10),
                        itemName: item.name ?? '',
                        quantity: item.newQuantity,
                        user: username,
                        descr: 'artículo utilizado en orden de trabajo'
                    };
    
                    API.post('benackoAPI', '/v2/inventory-actions', {body: inventoryAction});
                })
                .catch(err => errorNotification(err));
    };


    const selectInventory =
        <Row style={{width: '100%', height: 100, marginTop: 20}}>
            <span style={{marginLeft: 20, width: '100%'}}>Selecciona el inventario</span>

            <Col offset={1} span={22}>
                <Select
                    style={{width: '100%'}}
                    onChange={setSelectedInventory}
                    options={inventories}
                    loading={loading}
                />
            </Col>
        </Row>;

    const selectInventoryItems =
        <Row style={{width: '100%', height: 100, marginTop: 20}}>{console.log()}
            <span style={{marginLeft: 20, width: '100%'}}>Selecciona los artículos</span>

            <Col offset={1} span={22}>
                <Select
                    style={{width: '100%'}}
                    mode='multiple'
                    onChange={setSelectedItems}
                    options={options}
                    loading={loading}
                />
            </Col>
        </Row>;
    
    const setSupplies =
        <Row style={{width: '100%', marginTop: 20, marginBottom: 30}}>
            <span style={{marginLeft: 20, width: '100%'}}>Ajusta las cantidades de los artículos</span>

            <Col offset={1} span={22}>
                <Table
                    size='small'
                    bordered={true}
                    dataSource={tableItems}
                    columns={editColumns}
                    rowKey={'id'}
                    pagination={false}
                    scroll={{y: 400}}
                    locale={{
                        emptyText: 'No se encontraron datos'
                    }}
                />
            </Col>
        </Row>;

    const completeTransaction =
        <Row style={{width: '100%', marginTop: 20, marginBottom: 30}}>
            <span style={{marginLeft: 20, width: '100%'}}>Movimiento completado</span>
        </Row>;

    return (
        <Spin spinning={loading}>
            <Row style={{display: 'flex', flexFlow: 'column', height: '100%'}}>
                <Row style={{width: '100%', height: 40, marginBottom: 10}}>
                    <Col span={22}>
                        <Row style={{marginLeft: 40, width: '100%', height: 40}}>
                            <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>
                                        <NavLink to={'/app/maintenance/work-orders'}>{'Órdenes de trabajo'}</NavLink>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <NavLink to={'/app/maintenance/work-orders/' + workOrderId}>{'Detalle de orden de trabajo'}</NavLink>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        {'Agregar artículos'}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Col>
                </Row>

                <SectionTitle title='Agregar artículos'/>

                <Steps size='small' current={step} style={{width: '100%'}}>
                    <Steps.Step title='Seleccionar inventario'/>
                    <Steps.Step title='Seleccionar artículos'/>
                    <Steps.Step title='Cantidades'/>
                    <Steps.Step title='Completar'/>
                </Steps>
                <Row style={{width: '100%', marginBottom: 10}}>
                    {renderedStep}
                </Row>
                

                <Space style={{marginTop: 40}}>
                    <Button type='primary' onClick={() => setStep(step - 1)} disabled={step === 0 || step === 3}>Previo</Button>
                    <Button type='primary' onClick={() => setStep(step + 1)} disabled={step === 3}>Siguiente</Button>
                </Space>
            </Row>
        </Spin>
    );
};

export default AddSupplies;
